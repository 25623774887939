import './Home.css';

import Navbar1 from '../../componentes/Navbar/Navbar1';

import Page1 from '../Page1/Page1';

function Home() {
  return (
    <div className="homeAll">
      <nav>
        <Navbar1/>
      </nav>

        <section className='container mx-auto'>
          <Page1/>
        </section>

        {/* <section className='container mx-auto'>
          <Page1/>
        </section>
        <section className='container mx-auto'>
          <Page1/>
        </section>
        <section className='container mx-auto'>
          <Page1/>
        </section> */}
        
    </div>
  );
}

export default Home;
