import React from 'react';
import { Navigate } from 'react-router-dom';
import { UserAuth } from '../../contexts/contextsAuth/AuthContext';

  function RotaPrivada ({children}) {
    const {user} = UserAuth();

    if (!user){
      return <Navigate to="/"/>
    }  
  return children;
};


export default RotaPrivada;


