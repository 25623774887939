import React, { useState, useEffect } from "react";
import { getDocs, collection, doc, updateDoc } from "firebase/firestore";
import { database } from "../../firebase";
import { List, Button, Typography } from "antd";

const Comentarios = () => {
  const [comments, setComments] = useState([]);

  useEffect(() => {
    const fetchComments = async () => {
      const medicosSnapshot = await getDocs(collection(database, "medicos2"));
      const commentsData = [];

      for (let doc of medicosSnapshot.docs) {
        const medicName = doc.data().nome;

        const commentsSnapshot = await getDocs(collection(doc.ref, "comments"));

        commentsSnapshot.forEach((commentDoc) => {
          const commentData = commentDoc.data();
          if (commentData.mostrarComentario === false) {
            commentsData.push({
              ...commentData,
              id: commentDoc.id,
              medicId: doc.id,
              medicName: medicName,
            });
          }
        });
      }

      setComments(commentsData);
    };

    fetchComments();
  }, []);

  const handleShowComment = async (commentId, medicId) => {
    const commentRef = doc(
      database,
      "medicos2",
      medicId,
      "comments",
      commentId
    );
    await updateDoc(commentRef, {
      mostrarComentario: true,
    });

    setComments(comments.filter((comment) => comment.id !== commentId));
  };

  return (
    <List
      itemLayout="horizontal"
      dataSource={comments}
      renderItem={(comment) => (
        <List.Item
          actions={[
            <Button
              type="primary"
              onClick={() => handleShowComment(comment.id, comment.medicId)}
            >
              Exibir Comentário
            </Button>,
          ]}
        >
          <List.Item.Meta
            title={
              <>
                <Typography.Text>Médico: {comment.medicName}</Typography.Text>
                <br />
                <Typography.Text>Paciente: {comment.name}</Typography.Text>
                <br />
                {comment.timestamp && comment.timestamp.seconds ? (
                  <Typography.Text type="secondary">
                    Data:{" "}
                    {new Date(comment.timestamp.seconds * 1000).toLocaleString(
                      "pt-BR"
                    )}
                  </Typography.Text>
                ) : null}
              </>
            }
            description={`Comentário: ${comment.opinion} `}
          />
        </List.Item>
      )}
    />
  );
};

export default Comentarios;
