import "./Pesquisa.css";
import React, {
  Suspense,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faStar,
  faFilterCircleXmark,
  faSliders,
} from "@fortawesome/free-solid-svg-icons";
// import DefaultUser from "../../imgs/defaulUser.jpg";

import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { getDocs, collection } from "firebase/firestore";
import { database } from "../../firebase";
import {
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import queryString from "query-string";
import Navbar1 from "../../componentes/Navbar/Navbar1";
import ScrollToTop from "../../componentes/ScrollToTop/ScrollToTop";
import ErrorBoundary from "../../componentes/ErrorBoundary/ErrorBoundary";
import Loader from "../../componentes/Loader/Loader";

const Pesquisa = () => {
  let navigate = useNavigate();

  let location = useLocation();
  const queryParams = queryString.parse(location.search);

  const Resultados = React.lazy(() =>
    import("../../componentes/Resultados/Resultados")
  );

  let [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState(searchParams.get("query"));

  const [medico, setMedico] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");

  const [selectedEspecialidade, setSelectedEspecialidade] = useState(null);
  const [conv, setConv] = useState("");
  const [cidad, setCidad] = useState("");
  const [selectedRating, setSelectedRating] = useState(null);

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);

  const todosMedicos = collection(database, "medicos2");
  const [userImageUrl, setUserImageUrl] = useState(null);

  useEffect(() => {
    const getTodosMedicos = async () => {
      try {
        const data = await getDocs(todosMedicos);
        const medicosData = data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        setMedico(medicosData);
        setFilteredData(medicosData);
      } catch (err) {
        console.error(err);
      }
    };

    getTodosMedicos();
  }, []);

  const applyFilters = useCallback(() => {
    let filteredData = medico;

    if (wordEntered.trim() !== "") {
      filteredData = filteredData.filter((medicos2) =>
        medicos2.nome.toLowerCase().includes(wordEntered.toLowerCase())
      );
    }

    if (selectedEspecialidade !== null) {
      filteredData = filteredData.filter(
        (medicos2) => medicos2.especialidade === selectedEspecialidade
      );
    }

    if (conv !== "") {
      filteredData = filteredData.filter(
        (medicos2) => medicos2.convenio && medicos2.convenio.includes(conv)
      );
    }

    if (cidad !== "") {
      filteredData = filteredData.filter(
        (medicos2) => medicos2.cidade && medicos2.cidade.includes(cidad)
      );
    }
    if (selectedRating !== null) {
      filteredData = filteredData.filter(
        (medicos2) => Math.ceil(medicos2.rateavg) === selectedRating
      );
    }

    setFilteredData(filteredData);
  }, [wordEntered, selectedEspecialidade, conv, cidad, medico, selectedRating]);

  const handleFilter = (event) => {
    const searchWord = event?.target?.value.trim().toLowerCase() || "";
    setWordEntered(searchWord);
  };

  useEffect(() => {
    const params = new URLSearchParams(searchParams);
    const q = params.get("q");
    applyFilters();

    if (q) {
      setSelectedEspecialidade(q || "");
    }
  }, [searchParams, applyFilters]);

  const resetFilter = () => {
    setSelectedEspecialidade(null);
    setConv("");
    setCidad("");
    setSelectedRating(null);
    setSearchParams("");
  };

  useEffect(() => {
    applyFilters();
  }, [wordEntered, selectedEspecialidade, conv, cidad]);

  const handleEspecialidadeChange = (especialidade) => {
    setSelectedEspecialidade(especialidade);
    setSearchParams({ q: especialidade });
  };

  const handleConvChange = (eventKey) => {
    setConv(eventKey);
  };

  const especialidades = [
    "Alergia e imunologia",
    "Anestesiologia",
    "Angiologia e Cirurgia Vascular",
    "Cancerologia (Oncologia)",
    "Cardiologia",
    "Cirurgia Cardiovascular",
    "Cirurgia da Mão",
    "Cirurgia de Cabeça e Pescoço",
    "Cirurgia do Aparelho Digestivo",
    "Cirurgia Geral",
    "Cirurgia Pediátrica",
    "Cirurgia Plástica",
    "Cirurgia Torácica",
    "Cirurgia Vascular",
    "Clínica Médica",
    "Coloproctologia",
    "Dermatologia",
    "Endocrinologia e Metabologia",
    "Endoscopia",
    "Gastroenterologia",
    "Genética Médica",
    "Geriatria",
    "Ginecologia e Obstetrícia",
    "Hematologia e Hemoterapia",
    "Homeopatia",
    "Infectologia",
    "Mastologia",
    "Medicina de Emergência",
    "Medicina de Família e Comunidade",
    "Medicina do Esporte",
    "Medicina do Trabalho",
    "Medicina de Tráfego",
    "Medicina Física e Reabilitação",
    "Medicina Intensiva",
    "Medicina Legal e Perícia Médica",
    "Medicina Nuclear",
    "Medicina Preventiva e Social",
    "Nefrologia",
    "Neurocirurgia",
    "Neurologia",
    "Nutrologia",
    "Oftalmologia",
    "Ortopedia e Traumatologia",
    "Otorrinolaringologia",
    "Patologia",
    "Patologia Clínica/Medicina Laboratorial",
    "Pediatria",
    "Pneumologia",
    "Psiquiatria",
    "Radiologia e Diagnóstico por Imagem",
    "Radioterapia",
    "Reumatologia",
    "Urologia",
  ];

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleFullScreenMenu = () => {
    if (isMenuOpen) {
      setShow(false);
      setShow2(false);
      setShow3(false);
      setShow4(false);
    }
    setIsMenuOpen(!isMenuOpen);
  };

  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        if (isMenuOpen) {
          setShow(false);
          setShow2(false);
          setShow3(false);
          setShow4(false);
          setIsMenuOpen(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <div className="container mx:auto tudoPesquisa">
      <ScrollToTop></ScrollToTop>
      <nav>
        <Navbar1></Navbar1>
      </nav>

      <div className="group1">
        <div
          className={`filtros lateral ${isMenuOpen ? "active" : ""}`}
          ref={sidebarRef}
        >
          <Dropdown show={show}>
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              className="filter"
              onClick={() => setShow(!show)}
            >
              {selectedEspecialidade ? selectedEspecialidade : "Especialidade"}
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{
                maxHeight: "200px",
                overflowY: "auto",
                overflowX: "none",
              }}
            >
              {especialidades.map((especialidade, index) => (
                <Dropdown.Item
                  key={index}
                  value={especialidade}
                  onClick={() => handleEspecialidadeChange(especialidade)}
                  className="lettersize"
                >
                  {especialidade}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown onSelect={handleConvChange} show={show2}>
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              className="filter"
              onClick={() => setShow2(!show2)}
            >
              {conv ? conv : "Convênio"}
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{
                maxHeight: "200px",
                overflowY: "auto",
                overflowX: "none",
              }}
            >
              <Dropdown.Item className="lettersize" eventKey="Unimed">
                Unimed
              </Dropdown.Item>
              <Dropdown.Item className="lettersize" eventKey="CedPlan">
                CedPlan
              </Dropdown.Item>
              <Dropdown.Item className="lettersize" eventKey="Amil">
                Amil
              </Dropdown.Item>
              <Dropdown.Item className="lettersize" eventKey="Bradesco">
                Bradesco Saúde
              </Dropdown.Item>
              <Dropdown.Item className="lettersize" eventKey="Intermédica">
                Intermédica Saúde
              </Dropdown.Item>
              <Dropdown.Item className="lettersize" eventKey="SulAmérica">
                SulAmérica Saúde
              </Dropdown.Item>
              <Dropdown.Item className="lettersize" eventKey="Golden Cross">
                Golden Cross
              </Dropdown.Item>
              <Dropdown.Item className="lettersize" eventKey="Não possui">
                Não possui
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown show={show3}>
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              className="filter"
              onClick={() => setShow3(!show3)}
            >
              {cidad ? cidad : "Cidade"}
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{
                maxHeight: "200px",
                overflowY: "auto",
                overflowX: "none",
              }}
            >
              <Dropdown.Item className="lettersize" eventKey="Barbacena">
                Barbacena
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown show={show4}>
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              className="filter"
              onClick={() => setShow4(!show4)}
            >
              {selectedRating
                ? Array(selectedRating)
                    .fill()
                    .map((_, i) => (
                      <FontAwesomeIcon key={i} id="estrela" icon={faStar} />
                    ))
                : "Avaliação"}
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{
                maxHeight: "200px",
                overflowY: "auto",
                overflowX: "none",
              }}
            >
              {[1, 2, 3, 4, 5].map((rating) => (
                <Dropdown.Item
                  key={rating}
                  onClick={() => setSelectedRating(rating)}
                  className="lettersize"
                >
                  {Array(rating)
                    .fill()
                    .map((_, i) => (
                      <FontAwesomeIcon key={i} id="estrela" icon={faStar} />
                    ))}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <Button
            variant="outline-secondary"
            className="filter xMrk"
            onClick={resetFilter}
          >
            <FontAwesomeIcon id="filterDown" icon={faFilterCircleXmark} />
          </Button>
          {/* </div> */}
        </div>

        <div className="btnsMobile">
          <Button
            variant="outline-secondary"
            className="filter xMrk"
            id="mobileScreenMenu1"
            onClick={resetFilter}
          >
            <FontAwesomeIcon id="filterDown" icon={faFilterCircleXmark} />
          </Button>
          <Button
            variant="outline-secondary"
            className="filter xMrk"
            id="mobileScreenMenu"
            onClick={toggleFullScreenMenu}
          >
            <FontAwesomeIcon id="filterDown" icon={faSliders} />
          </Button>
        </div>

        <div className="bg">
          <div className="textInput tamanho">
            <InputGroup id="input1" className="mb-3">
              <FontAwesomeIcon id="lupa" icon={faMagnifyingGlass} />
              <Form.Control
                id="input2"
                placeholder="João da Silva"
                aria-label="doutores"
                aria-describedby="basic-addon2"
                onChange={handleFilter}
              />
              <Button
                variant="outline-secondary"
                id="button-addon2"
                onClick={applyFilters}
              >
                Pesquisar
              </Button>
            </InputGroup>
          </div>

          <Suspense fallback={<Loader></Loader>}>
            <ErrorBoundary>
              <Resultados filteredData={filteredData} navigate={navigate} />
            </ErrorBoundary>
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default Pesquisa;
