import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserAuth } from "../../contexts/contextsAuth/AuthContext";
import { Layout, Menu, Breadcrumb, theme, Alert } from "antd";
import {UserOutlined} from "@ant-design/icons";
import Navbar1 from "../../componentes/Navbar/Navbar1";

import {
  doc,
  getDoc,
  deleteDoc,
  getDocs,
  collection,
  where,
} from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";
import { signOut } from "firebase/auth";
import { auth, database } from "../../firebase.js";
// import devenceLogo from "../../imgs/devence.png";

import EditarFoto from "../CadastroMed/EditarFoto.js";
import EdicaoMed from "../CadastroMed/EdicaoMed.js";
import CadastroMed from "../CadastroMed/CadastroMed.js";
// import Comments from "../../componentes/comments/Comments.js";
// import Rating from "../../componentes/rating/Rating.js";
import Comentarios from "./Comentarios.js";

const { Content, Sider } = Layout;
const Admin = () => {
  const { userId } = useParams();
  const { user, logout } = UserAuth();
  const navigate = useNavigate();
  const [userHasProfile, setUserHasProfile] = useState(false);

  const [activeComponent, setActiveComponent] = useState("");
  const [medicosCount, setMedicosCount] = useState(0);

  useEffect(() => {
    const allowedUserIds = ["M4uQRWkuqcNiKsBkhNGPy7gXjnl2", "idMatheus"];
    if (!user || !allowedUserIds.includes(userId)) {
      navigate(`/`);
    }
  }, [userId, navigate]);

  useEffect(() => {
    const checkUserProfile = async () => {
      const docSnap = await getDoc(doc(database, "medicos2", user.uid));
      setUserHasProfile(docSnap.exists());
    };
    checkUserProfile();
  }, [user]);

  useEffect(() => {
    const fetchMedicos = async () => {
      const medicosSnapshot = await getDocs(collection(database, "medicos2"));
      setMedicosCount(medicosSnapshot.size);
    };

    fetchMedicos();
  }, []);

  const storage = getStorage();
  const removeUserProfile = async () => {
    try {
      const imageRef = ref(storage, `images/${user.uid}`);
      await deleteObject(imageRef);
      await deleteDoc(doc(database, "medicos2", user.uid));
      <Alert message="Perfil removido com sucesso." type="success" />;
    } catch (e) {
      <Alert message="Erro ao remover perfil." type="error" />;
    }
  };

  const [comments, setComments] = useState([]);

  useEffect(() => {
    const fetchComments = async () => {
      const medicosSnapshot = await getDocs(collection(database, "medicos2"));
      const commentsData = [];

      for (let doc of medicosSnapshot.docs) {
        const commentsSnapshot = await getDocs(
          collection(doc.ref, "comments"),
          where("mostrarComentario", "==", false)
        );

        commentsSnapshot.forEach((commentDoc) => {
          commentsData.push(commentDoc.data());
        });
      }

      setComments(commentsData);
    };

    fetchComments();
  }, []);

  const sidebarOptions = [
    {
      key: "Perfil",
      icon: <UserOutlined />,
      label: "Perfil",
      children: [
        {
          key: 10,
          label: (
            <button
              onClick={() =>
                setActiveComponent(
                  userHasProfile ? "EditProfile" : "CreateProfile"
                )
              }
            >
              {userHasProfile ? "Editar Perfil" : "Criar Perfil"}
            </button>
          ),
        },
        {
          key: 11,
          label: (
            <button onClick={() => setActiveComponent("EditPhoto")}>
              Editar Foto
            </button>
          ),
        },
        {
          key: 12,
          label: (
            <button onClick={() => setActiveComponent("Comments")}>
              Revisar Comentários
            </button>
          ),
        },
      ],
    },
    // {
    //   key: "financeiro",
    //   icon: <CreditCardOutlined />,
    //   label: "Financeiro",
    //   children: [
    //     { key: 20, label: "Realizar pagamento" },
    //     { key: 21, label: "Histórico" },
    //   ],
    // },
  ];

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/");
      console.log("Você não está conectado.");
    } catch (e) {
      console.log(e.message);
    }
  };
  return (
    <Layout style={{ marginTop: "75px" }}>
      <Navbar1 />
      <Layout>
        <Sider width={200} style={{ background: theme.colorBgContainer }}>
          <Menu
            mode="inline"
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={sidebarOptions.map((option) => option.key)}
            style={{ height: "100vh", borderRight: 0 }}
          >
            {sidebarOptions.map((item) => (
              <Menu.SubMenu key={item.key} title={item.label} icon={item.icon}>
                {item.children.map((child) => (
                  <Menu.Item key={child.key}>{child.label}</Menu.Item>
                ))}
              </Menu.SubMenu>
            ))}
          </Menu>
        </Sider>
        <Layout style={{ padding: "0 24px 24px" }}>
          <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>Painel do Administrador</Breadcrumb.Item>
          </Breadcrumb>
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              background: theme.colorBgContainer,
            }}
          >
            <div className="container">
              {!activeComponent && <h2>Médicos Cadastrados: {medicosCount}</h2>}

              {activeComponent === "Comments" && (
                <Comentarios />
              )}
              {activeComponent === "EditProfile" && (
                <EdicaoMed userId={user.uid} />
              )}
              {activeComponent === "CreateProfile" && (
                <CadastroMed userId={user.uid} />
              )}
              {activeComponent === "EditPhoto" && (
                <EditarFoto userId={user.uid} />
              )}
            </div>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Admin;
